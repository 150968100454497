<template>
  <div class="flex flex-col h-full">
    <WizardIndicator
      :pages="indicatorPages"
      :current-step="currentStep"
    />

    <div class="flex-1 overflow-auto" :key="currentStep">
      <component v-if="activePage" :is="activePage"/>
    </div>

    <div class="flex justify-between items-center pt-4">
      <div>
        <button
          type="button"
          class="btn-primary btn-transparent"
          @click="cancel"
        >
          cancel
        </button>
      </div>
      <div class="flex">
        <button
          v-if="this.currentStep > 0"
          type="button"
          class="btn-primary mr-4"
          @click="goBack"
        >
          back
        </button>
        <button
          type="submit"
          class="btn-primary btn-solid"
        >
          {{ primaryButton }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import WizardIndicator from '@/components/ui/wizard/WizardIndicator';
  export default {
    name: 'Wizard',
    components: {WizardIndicator},
    emits: ['cancel'],

    props: {
      submitButton: {
        required: false,
        default: 'submit',
      }
    },

    data: () => {
      return {
        currentStep: 0,
      };
    },

    computed: {
      pages() {
        return this.$slots.default()
          .filter(page => page.type.name === 'WizardPage');
      },

      indicatorPages() {
        return this.pages.map(page => page.props);
      },

      activePage() {
        return this.pages[this.currentStep];
      },

      primaryButton() {
        return this.currentStep < this.pages.length - 1
          ? 'next'
          : this.submitButton;
      },
    },

    methods: {
      goBack() {
        this.currentStep -= 1;
      },

      goNext() {
        this.currentStep += 1;
      },

      setStep(step) {
        this.currentStep = step;
      },

      cancel() {
        this.$emit('cancel');
      },
    },
  };
</script>
