<template>
  <div class="steps">
    <div
      v-for="(step, index) in pages"
      :key="index"
      class="step"
      :class="{
        'step--passed': index < currentStep,
        'step--active': index === currentStep,
      }"
    >
      <div class="step__point">
        <Icon
          v-if="index < currentStep"
          name="checkmark"
          class="step__checkmark"
        />
      </div>
      <span>{{step.title}}</span>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  components: {Icon},

  props: {
    pages: {
      type: Array,
      required: true,
    },

    currentStep: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.steps {
  @apply flex relative mb-8;
}

.step {
  @apply flex flex-col flex-1 items-center relative text-gray-400 text-3xs;

  &__point {
    @apply flex justify-center items-center w-4 h-4 rounded-full mb-2 border-2 border-gray-300 bg-white text-white;
  }

  &__checkmark {
    @apply w-2 h-2;
  }

  &:before {
    @apply absolute border-t-2 border-gray-300;
    content: '';
    left: calc(-50% + 0.5rem);
    right: calc(50% + 0.5rem);
    top: 7px;
  }

  &:first-child:before {
    @apply hidden;
  }

  &--active,
  &--passed {
    @apply text-highlight-base;

    .step__point {
      @apply border-highlight-base;
    }

    &:before {
      @apply border-highlight-base;
    }
  }

  &--passed {
    .step__point {
      @apply bg-highlight-base;
    }
  }
}
</style>
