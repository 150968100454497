<template>
  <div>
    <slot/>
  </div>
</template>

<script>
  export default {
    name: 'WizardPage',

    props: {
      title: {
        type: String,
        required: true,
      },
    },

    data: () => {
      return {
        isActive: false,
      }
    },
  };
</script>
