<template>
  <FinalField :name="name" :validate="validate" ref="field">
    <template v-slot="props">
      <div class="form-col">
        <label v-if="label" :for="name">{{label}}</label>
        <div v-if="!editMode" :class="textClass">{{formatValue(props.value)}}</div>
        <div class="flex" v-if="editMode">
          <div class="w-1/4 phone-code" :class="{'hidden-prefix': hidePrefix}">
            <Dropdown
              :options="countries"
              :value="code"
              @input="handleDropdownInput"
              no-clear
            />
          </div>
          <div class="w-3/4 flex-1 relative">
            <the-mask
              :data-test="name"
              type="text"
              class="form-input"
              mask="(###) ###-####"
              v-model="number"
              v-on="fieldEvents"
              :placeholder="placeholder"
              :name="props.name"
              :disabled="disabled"
              :class="{
                error: props.meta.error && props.meta.touched,
                'form-input--clearable': clearable
              }"
            />
            <button
              v-if="clearable && props.value"
              class="absolute right-0 top-0 bottom-0 p-2 text-gray-400 outline-none focus:outline-none"
              type="button"
              @click.stop="clearValue"
            >
              <icon class="w-5 h-5" name="closeCircle2"/>
            </button>
          </div>
        </div>
        <span class="form-hint" v-if="helperText">{{helperText}}</span>
        <span class="form-hint form-error" v-if="props.meta.error && props.meta.touched">
          {{ props.meta.error }}
        </span>
      </div>
    </template>
  </FinalField>
</template>

<script>
  import {FinalField} from 'vue-final-form';
  import Dropdown from "@/components/ui/Dropdown";
  import {countries} from "@/utils/countryCodes";
  import {parsePhoneNumberFromString} from 'libphonenumber-js';
  import {TheMask} from 'vue-the-mask';
  import Icon from "@/components/ui/Icon";

  export default {
    name: "PhoneInput",
    components: {
      Dropdown,
      FinalField,
      TheMask,
      Icon,
    },
    data() {
      return {
        countries: countries.map(({iso2: key, dialCode}) => ({key, value: `${key} (+${dialCode})`})),
        code: 'US',
        number: undefined,
        initialized: false,
      }
    },
    computed: {
      fieldEvents() {
        return {
          blur: () => this.$refs.field.fieldState.blur(),
          focus: () => this.$refs.field.fieldState.focus(),
        }
      }
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      validate: {
        type: [Function, Array],
        required: false,
      },
      disabled: {
        type: Boolean,
        default: false
      },
      editMode: {
        type: Boolean,
        default: true,
      },
      textClass: {
        type: String
      },
      hidePrefix: {
        type: Boolean,
        default: true,
      },
      clearable: {
        type: Boolean,
        required: false,
        default: false
      },
        /**
         * Helper text rendered under the field
         */
        helperText: {
            type: String,
        },
    },
    emits: ['clear'],
    methods: {
      getCountry(code) {
        return countries.find(c => c.iso2 === code);
      },
      getValueFromFieldState() {
        const fieldValue = this.$refs.field?.fieldState.value;

        if (fieldValue) {
          const phoneNumber = parsePhoneNumberFromString(fieldValue);

          if (phoneNumber?.country) {
            this.code = phoneNumber.country;
          }

          if (phoneNumber?.nationalNumber) {
            this.number = phoneNumber.nationalNumber;
          }
        }
      },
      formatValue(value = '') {
        return parsePhoneNumberFromString(value)?.formatNational() ?? '-';
      },
      handleDropdownInput(val) {
        if (this.initialized) {
          this.code = val;
        }
      },
      clearValue() {
        this.number = undefined;
        this.$emit('clear');
      },
    },
    watch: {
      number(val) {
        const country = this.getCountry(this.code);
        const newVal = val ? '+' + country?.dialCode + val : undefined;
        this.$refs.field.fieldState.change(newVal);
      },
      code(val) {
        if (this.number) {
          const country = this.getCountry(val);
          this.$refs.field.fieldState.change(`+${country?.dialCode}${this.number}`);
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.getValueFromFieldState();
        this.initialized = true;
      });
      this.$watch('$refs.field.fieldState.value', () => {
        this.getValueFromFieldState();
      })
    }
  }
</script>
<style scoped>
  .hidden-prefix {
    max-width: 0;
    overflow: hidden;
  }

  .form-input--clearable {
      @apply pr-8;
  }
</style>
